* {
  font-family: "Nunito", "Avenir", sans-serif;
}

html {
  box-sizing: border-box;
}

*, *:before, *:after {
  box-sizing: inherit;
}
